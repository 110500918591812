import React, { useEffect, useContext } from "react";
import { Router, Route, Switch } from "react-router-dom";
import Sidebar from "components/sidebar";
import PageContent from "components/page-content/content";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "components/Loading";
import history from "./utils/history";
import Error from "./pages/error/general";
import VerifyEmail from "./pages/error/verify-email";
import { AuthConfigContext } from "context/AuthConfigProvider";
import ProfileDetailsProvider from "context/ProfileDetailsProvider";
import Login from "pages/login";
import Email from "pages/email";

// styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./global.scss";

const ProtectedRoute = ({ component, ...args }) => {

  // 首先创建一个URL对象
  const url = new URL(window.location.href);
  // 使用URLSearchParams获取查询参数
  const params = new URLSearchParams(url.search);
  // 获取特定的查询参数
  const param = params.get('maintenance'); // 返回 'value1'
    if(param){
      localStorage.setItem('maintenance','1');
    }
    if(!param && !localStorage.getItem('maintenance')){
    console.log('go to maintenance')
    window.location.href = "/maintenance";
    return
  }
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
    returnTo:"/",
    ...args,
  });
  return <Component />;
};

const MaintenanceComponent = () => {
  return (
    <div className="maintenance-content">
      <div>ただいまシステムメンテナンス中です。</div>
      <div>メンテナンス期間：</div>
      <div>2024年12月4日（水曜日） 22:00 〜2024年12月5日（木曜日）6:00</div>
    　<div>ご迷惑おかけいたしますが、今しばらくお待ちください。</div>
    </div>
  );
};

const App = () => {
  const { isLoading, error } = useAuth0();
  const authConfig = useContext(AuthConfigContext);
  const brandUi = authConfig.configJson.brand_ui;
  // useEffect(() => {
  //   var titleImg = document.querySelector("link[rel*='icon']");
  //   if (brandUi && brandUi.logo) {
  //     titleImg.href = brandUi.logo
  //   }
  // },[])

  useEffect(() => {
    const setBrandColors = () => {
      if (brandUi && brandUi.backgroundColor) {
        document.documentElement.style.setProperty(
          "--color-brand-bg",
          brandUi.backgroundColor
        );
      }
      if (brandUi && brandUi.primaryColor) {
        document.documentElement.style.setProperty(
          "--color-brand-primary",
          brandUi.primaryColor
        );
      }
    };
    setBrandColors();
  }, [brandUi, authConfig]);

  if (error) {
    console.log("error message:", error.message);
    if (
      error.message ===
        "User's email is not verified yet. Please check your email for verification email" ||
      error.message ===
        "User's email is not verified yet. Please check your email for verification email."
    ) {
      return <VerifyEmail error={error.message} />;
    } else if (
      error.message === "Invalid state" ||
      error.message === "grant request is invalid"
    ) {
      window.location.href =
        window.location.origin + (authConfig.configJson.redirectUri || "");
    } else {
      return <Error error={error.message} />;
    }
    // localStorage.setItem("error", error.message);
    // window.location.href = window.location.origin;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div className="outer-container">
        {authConfig?.configJson?.maintenance ? (
          <Switch>
            <Route
              exact
              path={`/profile/create`}
              component={MaintenanceComponent}
            />
            <Route
              exact
              path={`/profile/create/buy`}
              component={MaintenanceComponent}
            />
            <Route
              exact
              path={`/ecommerce/login`}
              component={MaintenanceComponent}
            />
             <Route
              exact
              path={`/`}
              component={Login}
            />
            <Route
              exact
              path={`/email`}
              component={Email}
            />
            <ProfileDetailsProvider>
              <Sidebar />
              <ProtectedRoute
                component={PageContent}
                returnTo={authConfig.configJson.redirectUri || "/"}
              />
            </ProfileDetailsProvider>
          </Switch>
        ) : (
          <Switch>
            <Route
              exact
              path={`/`}
              component={Login}
            />
            <Route
              exact
              path={`/maintenance`}
              component={MaintenanceComponent}
            />
             <Route
              exact
              path={`/email`}
              component={Email}
            />
          <ProfileDetailsProvider>
            <Sidebar />
            <ProtectedRoute
              component={PageContent}
              returnTo={authConfig.configJson.redirectUri || "/"}
            />
          </ProfileDetailsProvider>
          </Switch>
        )} 
      </div>
    </Router>
  );
};

export default App;
